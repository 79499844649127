



























































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import FormBody from "@/components/form/form-body.vue";

export default mixins(GeneralMixin).extend({
  name: "EquipmentCaractModal",
  props: [
    "device",
    "name",
    "secondaryButtons",
    "formLink",
    "hasParentAction",
    "excludeItemTypes",
    "excludeItemIds",
    "excludeSectionIds",
    "prevButtonTextOveride",
    "nextButtonTextOveride",
    "prevButtonActionOveride",
    "nextButtonActionOveride",
    "onCompletedActionOveride",
  ],
  components: { FormBody },
  beforeMount() {
    /** Update modal state before mount */
    this.$store.commit("toggleModalState", {
      name: `equipment_characterization_${this?.device?.id}`,
      status: false,
    });
  },
  computed: {
    isModalVisible: {
      get(): string {
        return this.$store.getters["modalState"](this.name);
      },
      set() {
        this.closeModal(this.name);
      },
    },
  },
});
