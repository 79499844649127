





























































































































































import mixins from "vue-typed-mixins";
import { ProfileMixin } from "@/mixins/participant-general-mixin";
import SolidButton from "@/components/general/solid-button.vue";
import FormBody from "@/components/form/form-body.vue";
import FormModal from "@/components/general/form-modal.vue";
import config from "@/config";

interface ThirdPartyIntegrations {
  description: string;
  expires_at: string;
  key: string;
}

interface UserProfile {
  email: string;
  first_name: string;
  last_name: string;
  credit_balance: number;
  participant_type: string;
  has_sensor: boolean;
  third_party_integration?: ThirdPartyIntegrations[];
  passport_photo?: string;
  isEv4eu?: boolean;
}

export default mixins(ProfileMixin).extend({
  name: "ParticipantProfile",
  components: {
    SolidButton,
    FormBody,
    FormModal,
  },
  data() {
    return {
      fieldVisibility: [],
    };
  },
  beforeCreate() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store.dispatch("participantGeneral/getProfileInfo");
  },
  computed: {
    hasThirdPartyIntegrations(): boolean {
      const userProfile: UserProfile = this.profile_info;
      return !!userProfile?.third_party_integration?.length;
    },
    formExcludedSections(): number[] {
      return config?.formsExcludedSections?.participantProfile;
    },
  },
  methods: {
    toggleFieldVisibility(index: number) {
      this.$set(this.fieldVisibility, index, !this.fieldVisibility[index]);
    },
    getExpirationDate(integration: any): string {
      if (!integration?.expires_at) return "";

      const expirationDate = new Date(integration.expires_at);
      return expirationDate.toLocaleDateString("pt-PT", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    async savePhoto(
      sectionId: number,
      itemId: number,
      field_ref: string,
      btnName: string
    ): Promise<void> {
      await this.$store.dispatch("toggleSnackbar", null);
      const file_name = await this.uploadFile(
        itemId,
        field_ref,
        btnName,
        0,
        1,
        false,
        ""
      );
      if (file_name !== false) {
        this.$store.commit("toggleButtonState", {
          name: btnName,
          status: true,
        });
        const value = this.getFormIndexes({
          sectionId: sectionId,
          itemId: itemId,
          itemVal: file_name,
          pageCounter: 0,
        });
        this.$store.commit("formRecord/updateItemVal", value);
        const response = await this.$store.dispatch(
          "formRecord/postActiveForm",
          true
        );
        if (response === true) {
          this.$store.commit("formRecord/updateActiveForm", {
            already_submitted: false,
          });
          await this.$store.dispatch("participantGeneral/getProfileInfo");
        }
        this.$store.commit("toggleButtonState", {
          name: btnName,
          status: false,
        });
      }
    },
  },
});
