














































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import ParticipantNoContentInfoCard from "@/components/participant/participant-no-content-info-card.vue";
import FormBody from "@/components/form/form-body.vue";
import SolidButton from "@/components/general/solid-button.vue";

export default mixins(GeneralMixin).extend({
  name: "EquipmentDetailsModal",
  components: { ParticipantNoContentInfoCard, FormBody, SolidButton },
  props: {
    formLink: { type: String },
    device: { type: Object, required: true },
    name: { type: String, required: true },
  },
  data() {
    return {
      isFormVisible: true,
    };
  },
  beforeMount() {
    /** Update modal state before mount */
    this.$store.commit("toggleModalState", {
      name: `equipment_details_${this?.device?.id}`,
      status: false,
    });
  },
  computed: {
    isModalVisible: {
      get(): string {
        return this.$store.getters["modalState"](this.name);
      },
      set() {
        this.closeModal(this.name);
      },
    },
  },
  methods: {
    onFormEmpty(isEmpty: boolean) {
      this.isFormVisible = !isEmpty;
    },
    onClickCharacterize(): void {
      const { id } = this?.device;
      if (!id) return;

      this.$store.commit("toggleModalState", {
        name: `equipment_characterization_${id}`,
        status: true,
      });
      this.$store.commit("toggleModalState", {
        name: `equipment_details_${id}`,
        status: false,
      });
      this.isFormVisible = true;
    },
  },
});
