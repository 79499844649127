











































































































































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general-mixin";
import solidButton from "./solid-button.vue";
import router from "@/router";

export default mixins(GeneralMixin).extend({
  components: { solidButton },
  name: "ProjectDetailsModal",
  props: ["project", "isOnCourse", "isGeneral", "modalName"],
  data() {
    return {
      checked: false,
      hasScrolledToBottom: false,
      showRedirectLoader: false,
    };
  },
  methods: {
    checkCorrect() {
      this.checked = !this.checked;
    },
    onStartActivity() {
      let redirect_url = this?.project?.redirect_url;

      // Check if redirect_url contains the base path and remove it
      const basePath = process?.env?.VUE_APP_API_BASE_URL;
      if (redirect_url && redirect_url.includes(basePath)) {
        redirect_url = redirect_url.replace(basePath, "");
      }

      if (redirect_url) {
        this.showRedirectLoader = true;
        setTimeout(() => {
          this.onSetProjectActive();
          // Fetch the updated task notification
          this.$store.dispatch("activityData2/getNotsProjAct");
          router.push(redirect_url);
        }, 3000);
        return;
      }

      this.onSetProjectActive();
    },
    onCloseActivityDetailsModal() {
      this.onSetProjectActive();
    },
    handleScroll: function (el: any) {
      if (
        el.srcElement.offsetHeight + el.srcElement.scrollTop >=
        el.srcElement.scrollHeight - 1
      ) {
        this.hasScrolledToBottom = true;
      }
    },
    onSetProjectActive(): void {
      this.$store.dispatch("activityData2/setActiveProj", this.project.id);
      this.closeModal(this.modalName);
    },
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
